<template>
  <div class="Table DLTable">
    <div class="Table__toolbar">
      <div class="Table__toolbar-search">
        <search-input v-if="hasSearch" :value.sync="searchInput" />
        <slot name="left" />
      </div>
      <div class="Table__toolbar-buttons">
        <slot name="right" />
        <add-new-button v-if="hasAddButton" class-name="add-button" :func="onCreate" />
      </div>
    </div>

    <div class="table-responsive">
      <b-table
        ref="dl-table"
        :items="items"
        :fields="fields"
        sort-by="code"
        thead-class="Table__header DLTable__header"
        tbody-tr-class="Table__row DLTable__row"
        hover
      >
        <template #head()="data">
          <div v-if="!!data.label" class="header">
            <div class="header--value">
              {{ data.label }}
            </div>
            <div v-if="data.field.sortable" class="header--sort">
              <SortIcon />
            </div>
          </div>
          <div v-else class="action">
            {{ '' }}
          </div>
        </template>
        <template #cell(name)="data">
          <router-link
            :to="{
              name: 'department-detail',
              query: {
                id: data.item.id,
              },
            }"
          >
            {{ data.value }}
          </router-link>
        </template>
        <template #cell(actions)="data">
          <div class="cell">
            <img
              src="@/assets/icons/ic_edit.svg"
              alt="edit-icon"
              @click.stop.prevent="onEdit(data.item)"
            />
            <img
              src="@/assets/icons/ic_delete.svg"
              alt="delete-icon"
              @click.stop.prevent="onDelete(data.item.id)"
            />
          </div>
        </template>
      </b-table>
    </div>
    <div class="Table__paging">
      <table-paging
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
        :total="total"
        :options-paging="optionsPaging"
      />
    </div>

    <slot name="dialog" />
  </div>
</template>

<script>
import SearchInput from '@/components/commons/common-search-input'
import AddNewButton from '@/components/add-new-button'
import TablePaging from '@/components/commons/common-table-paging'
import SortIcon from '@/components/sort-icon'
import { departmentManagementService } from '@/services/department-management-service'

export default {
  name: 'DepartmentListTable',
  components: {
    SearchInput,
    AddNewButton,
    SortIcon,
    TablePaging,
  },
  props: {
    hasSearch: {
      type: Boolean,
      default: true,
    },
    hasAddButton: {
      type: Boolean,
      default: true,
    },
    showEditDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
    showAddDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
    showDeleteDialog: {
      type: Function,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      searchInput: '',
      items: [],
      fields: [
        {
          key: 'code',
          label: '部署ID',
          sortable: true,
        },
        {
          key: 'name',
          label: '部署名',
          sortable: true,
        },
        {
          key: 'members',
          label: '従業員数',
          sortable: true,
        },
        {
          key: 'apps',
          label: '利用中のサービス数',
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      currentPage: 1,
      perPage: 10,
      optionsPaging: [10, 50, 100],
      total: 0,
    }
  },
  watch: {
    searchInput(newValue) {
      this.search(newValue)
    },
    perPage() {
      this.search(this.searchInput)
    },
    currentPage() {
      this.search(this.searchInput)
    },
  },
  async created() {
    await this.loadTable()
  },
  methods: {
    onCreate() {
      this.showAddDialog(() => this.loadTable(true))
    },
    onEdit(rowData) {
      this.showEditDialog(rowData, () => this.loadTable(true))
    },
    onDelete(id) {
      this.showDeleteDialog(id, () => this.loadTable(true))
    },
    async loadTable(isMaster = false) {
      this.searchInput = ''
      const { items, total } = await departmentManagementService.getListDepartment(
        this.perPage,
        this.currentPage,
        this.searchInput,
        isMaster
      )
      this.items = items
      this.total = total
    },
    async search(keySearch) {
      const { items, total } = await departmentManagementService.getListDepartment(
        this.perPage,
        this.currentPage,
        keySearch
      )
      this.items = items
      this.total = total
    },
  },
}
</script>

<style></style>
